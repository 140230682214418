import React, { FC, useMemo } from 'react';

import { BankAccount, Organization } from 'store/api/api.types';
import { IconProps } from 'components/core/Icon/Icon.types';
import Badge from 'components/core/Badge/Badge';
import Icon from 'components/core/Icon/Icon';
import modalService from 'services/modalService';

import styles from './IdentityBankAccount.module.scss';

import { getBankTitle } from '../../../config/bankDetails.helpers';
import ModalBankAccountDetails from '../../../ModalBankAccountDetails/ModalBankAccountDetails';

const IdentityBankAccount: FC<{
  bankAccount: BankAccount;
  organizationId: Organization['id'];
}> = ({ bankAccount, organizationId }) => {
  const flagIcon = useMemo(
    () => `assetType${bankAccount.attributes.currency}` as IconProps['iconName'],
    [bankAccount],
  );

  return (
    <Badge
      className={styles.badge}
      color='grayLightest'
      onClick={() =>
        modalService.openModal(
          { title: 'Bank Details' },
          <ModalBankAccountDetails bankAccount={bankAccount} organizationId={organizationId} />,
        )
      }
    >
      <Icon iconName={flagIcon} marginRight={2} size={1.8} />
      <span>{getBankTitle(bankAccount.attributes)}</span>
      <Icon iconName='pencil' marginLeft={2} size={1.4} />
    </Badge>
  );
};

export default IdentityBankAccount;
